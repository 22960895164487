import React from "react";
import { Helmet } from "react-helmet";
import "./App.css";
import Header from "./components/header/Header";
import Home from "./components/home/Home";
import About from "./components/about/About";
import Skills from "./components/skills/Skills";
import Services from "./components/services/Services";
import Qualification from "./components/qualification/Qualification";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";
import ScrollUp from "./components/scrollup/ScrollUp";
import Project from "./components/projects/Project";

const App = () => {
  return (
    <>
      <Helmet>
        <title>Bernard George Charles</title>
        <meta name="keywords" content="BernardGeorgeCharles, Bernard, George, Bernard_George_Charles_, Bernard_George_Charles" />
        <meta name="description" content="Full-time geek exploring existence and building his own legacy. Join with me down below and let's get cracking!" />
        <meta name="subject" content="Designer and Developer" />
        <meta name="copyright" content="BernardGeorgeCharles" />
        <meta name="robots" content="max-snippet:-1, max-image-preview:large" />
        <meta name="topic" content="Bernard's portfolio" />
        <meta name="summary" content="Designer and Developer" />
        <meta name="Classification" content="Business" />
        <meta name="author" content="BernardGeorgeCharles, Bernardscreed@gmail.com" />
        <meta name="reply-to" content="BernardGeorgeCharles67@gmail.com" />
        <meta name="owner" content="BernardGeorgeCharles" />
        <meta name="url" content="https://www.bernardgeorgecharles.me/" />
        <meta name="identifier-URL" content="https://www.bernardgeorgecharles.me/" />
        <meta name="category" content="portfolio" />
        <meta name="coverage" content="Worldwide" />
        <meta name="distribution" content="Global" />
        <meta name="rating" content="General" />
        <meta name="og:title" content="Bernard George Charles"/>
        <meta name="og:type" content="portfolio"/>
        <meta name="og:url" content="https://www.bernardgeorgecharles.me/"/>
        <meta name="og:image" content="https://raw.githubusercontent.com/BernardGeorgeCharles/portfolio/main/src/assets/aboutRED.jpg"/>
        <meta name="og:site_name" content="BernardGeorgeCharles"/>
        <meta name="og:description" content="Full-time geek exploring existence and building his own legacy. Join with me down below and let's get cracking!"/>
        <meta name="og:email" content="bernardgeorgecharles007@gmail.com"/>
      </Helmet>
      <Header />
      <main className="main">
        <Home />
        <About />
        <Skills />
        <Services />
        <Qualification />
        <Project />
        <Contact />
      </main>
      <Footer />
      <ScrollUp />
    </>
  );
};

export default App;
